interface IProductConfig {
    id: string;
    name?: string;
    nameLc?: string;
    appInstances: string[];
    shortDescription?: string;
    icon?: string;
    iconClass?: string;
}

function getEnvUrlFragment(env: string, format: "." | "-"): string {
    if (env.startsWith("local")) {
        return `${format}test`;
    }

    if (env === "prod") {
        return "";
    }

    return `${format}${env}`;
}

function getPortalUrl(productName: string | null): string {
    if (!productName) {
        return "";
    }
    switch (productName) {
        case "Amsm":
            productName = "AmsmReporting";
            break;
        case "Dgea":
            productName = "DgeaDrce";
            break;
        case "Drce":
            productName = "DgeaDrce";
            break;
    }
    return `portal/${productName}`;
}

export function getMrdmProductUrl(
    env: string,
    product: IProductConfig,
    tenant: string = "dica",
    productName: string | null
): string {
    switch (product.id) {
        case "codman":
            return `https://codman.${tenant}${getEnvUrlFragment(
                env,
                "."
            )}.nl.mrdm.com/${getPortalUrl(productName)}`;
        case "survey":
            if (tenant === "logex") {
                return `https://dataentry.${tenant}${getEnvUrlFragment(env, ".")}.nl.logex.com/`;
            }
            return `https://dataentry${getEnvUrlFragment(env, "-")}.mrdm.eu/`;
        case "dataConnect":
            return `https://dataconnect${getEnvUrlFragment(env, "-")}.mrdm.eu/`;
        case "medicines":
            return `https://apps${getEnvUrlFragment(env, "-")}.mrdm.nl/codman/medicine`;
        default:
            return "";
    }
}

export const MRDM_PRODUCTS: IProductConfig[] = [
    {
        id: "codman",
        nameLc: "APP._MrdmProducts.Codman",
        appInstances: [
            "ApplicationInstance.CodmanIndicators.NL",
            "ApplicationInstance.CodmanDescriptives.NL",
            "ApplicationInstance.CodmanExploration.NL",
            "ApplicationInstance.CodmanPatients.NL",
            "ApplicationInstance.CodmanIndicators.CA",
            "ApplicationInstance.CodmanDescriptives.CA",
            "ApplicationInstance.CodmanExploration.CA",
            "ApplicationInstance.CodmanPatients.CA"
        ],
        // shortDescription: "CDM2021",
        iconClass: "codman-tool-icon"
    },
    {
        id: "survey",
        nameLc: "APP._MrdmProducts.Survey",
        appInstances: [
            "ApplicationInstance.Survey.NL", 
            "ApplicationInstance.DataEntry.NL",
            "ApplicationInstance.DataEntry.CA"
        ],
        // shortDescription: "SRV021",
        iconClass: "survey-tool-icon"
    },
    {
        id: "dataConnect",
        nameLc: "APP._MrdmProducts.DataConnect",
        appInstances: [
            "ApplicationInstance.DataConnect.NL",
            "ApplicationInstance.DataConnect.CA"
        ],
        // shortDescription: "DCN2021",
        iconClass: "data-connect-tool-icon"
    },
    {
        id: "medicines",
        nameLc: "APP._MrdmProducts.Medicines",
        appInstances: [
            "ApplicationInstance.CodmanMedicine.NL",
            "ApplicationInstance.CodmanMedicine.CA"
        ],
        // shortDescription: "MED2021",
        iconClass: "medicines-tool-icon"
    }
];
